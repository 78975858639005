$section-row-padding          : $vert-space*1.5 0 !default;
$section-inner-mobile-padding : 0 $horz-space/2 !default;
$section-inner-padding        : 0 $horz-space !default;



.section-row {
	padding:$section-row-padding;
	
	&.section-with-slider {
		padding:0;
	}
	
	.section-inner {
		overflow: hidden;
		clear: both;
	}
}

.postscript-first .section-row .section-inner {
	@include outer-container($site-max-width);
	padding:$section-inner-mobile-padding;
	@media (min-width:$narrow) {
		padding:$section-inner-padding;
	}
}

.section-row:not(.view-type-slider) {

	@media (min-width:540px) and (max-width:719px) {
	
		&.row-max-1, &.row-max-2, &.row-max-3, &.row-max-4, &.row-max-6, &.row-max-9 {
			 .views-row {
				 margin-right:0;
				 .card {
						margin-bottom:$vert-space;
						margin-right:0;
					}
			 }
		}
		
		&.row-max-12 .views-row {
			@include span-columns(1); 
			&:nth-child(12n+1) { clear:left; }
			&:nth-child(12n) { margin-right:0; }
		}
		
		&.row-max-6 .views-row,
    &.row-max-4 .views-row,
    &.row-max-3 .views-row,
    &.row-max-2 .views-row {
      @include span-columns(6);
			&:nth-child(2n) { margin-right:0; }
			&:nth-child(2n+1) { clear:left; }
		}
			
			
		&.row-max-12 .card-last-link-url {
			@include span-columns(1);
		}
    
		&.row-max-6 .card-last-link-url,
		&.row-max-4 .card-last-link-url
		&.row-max-3 .card-last-link-url,
		&.row-max-2 .card-last-link-url,
    &.row-max-6 .card-more-link,
		&.row-max-4 .card-more-link,
		&.row-max-3 .card-more-link,
		&.row-max-2 .card-more-link {
			@include span-columns(6);
			margin-right: 0;
		 }
	}

	@include media($narrow) {  
	
		&.row-max-1, &.row-max-2, &.row-max-3, &.row-max-4, &.row-max-6, &.row-max-9 {
			 .views-row {
				 margin-right:0;
				 .card {
						margin-right:0;
					}
			 }
		}
		
		&.row-max-12 .views-row {
			@include span-columns(1);
			&:nth-child(12n+1) { clear:left; }
			nth-child(12n) { margin-right:0; }
		}
			
		&.row-max-4 .views-row {
			@include span-columns(3);
			&:nth-child(4n) { margin-right:0; }
			&:nth-child(4n+1) { clear:left; }
		}
		
		&.row-max-3 .views-row {
			@include span-columns(4);
			&:nth-child(3n) { margin-right:0; }
			&:nth-child(3n+1) { clear:left; }
		}
			
		&.row-max-2 .views-row { @include span-columns(6);
			&:nth-child(2n) { margin-right:0; }
			&:nth-child(2n+1) { clear:left; }
		}
			
		&.row-max-12 .card-more-link,
    &.row-max-12 .card-last-link-url {
			@include span-columns(1);
			margin-right: 0;
		}
		
		&.row-max-6 .card-more-link,
		&.row-max-6 .card-last-link-url {
			@include span-columns(2);
			margin-right: 0;
		}
	
		&.row-max-4 .card-more-link,
		&.row-max-4 .card-last-link-url {
			@include span-columns(3);
			margin-right: 0;
		}
		
		&.row-max-3 .card-more-link,
		&.row-max-3 .card-last-link-url {
			@include span-columns(4);
			margin-right: 0;
		}
			
		&.row-max-2 .card-more-link,
		&.row-max-2 .card-last-link-url {
			@include span-columns(6);
			margin-right: 0;
		}
	}
	
	//If row-max-6 is selected but the page is under 960px in width, show 3 items per row instead
	@media (min-width:$narrow) and (max-width:959px) {
		&.row-max-6 .views-row {
			@include span-columns(4);
			&:nth-child(3n) { margin-right:0; clear:none;}
			&:nth-child(3n+1) { clear:left; }    
		}
		&.row-max-6 .card-more-link,
		&.row-max-6 .card-last-link-url {
			@include span-columns(4);
			margin-right: 0;
		}
	}
	
	//If row-max-6 is selected but the page has a sidebar, show 3 items per row instead (in content-main and content-additional only)

	@include media ($normal) {
		&.row-max-6 .views-row {
			@include span-columns(2);
			&:nth-child(6n) { margin-right:0; }
			&:nth-child(6n+1) { clear:left; }    
		 }
	}

	@media (min-width:500px) and (max-width:$normal) {
		.card-photo {
			float:left;
		}
	}
	
	.view-links-flexi .views-row .views-row {
		width: 100% !important;
	}
	
	.view-tagged-content.view-display-id-tagged_additional,
	.view-tagged-content.view-display-id-tagged_postscript,
	.view-tagged-content.view-display-id-tagged_sidebar {
		& > .view-content > .views-row {
	    width: 100% !important;
		}
	}
}

@include media ($normal) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.section-row:not(.view-type-slider) {
				&.row-max-6 .views-row {
					@include span-columns(4);
					&:nth-child(3n) { margin-right:0; }
					&:nth-child(3n+1) { clear:left; }
					&:nth-child(6n) { margin-right:inherit; }
					&:nth-child(6n+1) { clear:none; } 					
				}
			}
		}
	}
}

//If row-max-2 is selected and the page is more than 960px in width and DONT have sidebar second, show the image on the left
@media (min-width:$normal) {	
	.page-node:not(.sidebar-second) main,
	.postscript-first {
		.section-row:not(.view-type-slider) {
			&.row-max-2 .views-row {
//				article:not(.node-event) {
					.card-with-image {
						overflow: hidden;

						.card-image,
						.card-photo,
						.card-logo {							
							@include span-columns(6);
							margin-right: 0;	
						}
					}
//				}	
			}
		}
	}
}

//If row-max-4 is selected but the page is under 1200px in width and has sidebars, show 2 items per row instead
@media (min-width:$narrow) and (max-width:$wide) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.section-row:not(.view-type-slider) {
				&.row-max-4 .views-row {
				@include span-columns(6);
				&:nth-child(2n) { margin-right:0; clear:none;}
				&:nth-child(2n+1) { clear:left; }    
			 }
			}
		}
	}
}

//row-max-1
@media (min-width: 540px) and (max-width:$narrow) {
	.row-max-1 {
		article:not(.node-teaser-image),
		.custom-link-flexi {
			.card-with-image {
				overflow: hidden;

				.card-image,
				.card-photo,
				.card-logo {
					@include span-columns(5); //3
					margin-right: 0;
				}
			}
		}
	}
}
@media (min-width:$narrow) {
	.row-max-1 {
		article:not(.node-teaser-image),
		.custom-link-flexi {
			.card-with-image {
				overflow: hidden;
				
				.card-image,
				.card-photo,
				.card-logo {

					@include span-columns(5); //2 
					margin-right: 0;
				}
			}
		}
	}
}
//row-max-1 with sidebars
@media (min-width: 540px) and (max-width:$narrow) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.row-max-1 {
				article:not(.node-teaser-image),
				.custom-link-flexi {
					.card-with-image {
						overflow: hidden;

						.card-image,
						.card-photo,
						.card-logo {							
							@include span-columns(5); //3
							margin-right: 0;	

							picture,
							img {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width:$narrow) and (max-width:$normal) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.row-max-1 {
				article:not(.node-teaser-image),
				.custom-link-flexi {
					.card-with-image {
						overflow: hidden;

						.card-image,
						.card-photo,
						.card-logo {							
							@include span-columns(12); //3
							margin-right: 0;	

							picture,
							img {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width:$normal) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.row-max-1 {
				article:not(.node-teaser-image),
				.custom-link-flexi {
					.card-with-image {
						overflow: hidden;

						.card-image,
						.card-photo,
						.card-logo {							
							@include span-columns(5); //3
							margin-right: 0;	

							picture,
							img {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}
//cards within sidebar
@media (min-width:$mobile) {
	.side-row {
		&.row-max-1,
		&.row-max-2,
		&.row-max-3,
		&.row-max-4,
		&.row-max-6 {
			article:not(.node-teaser-image),
			.custom-link-flexi {
				.card-with-image {
					overflow: hidden;
					.card-image,
					.card-photo, 
					.card-logo {
						@include span-columns(12);
						margin-right: 0;
						float:none;
					}
				}
			}
		}
	}
}
 
section .section-row.row-bg-video {
	padding: 0;
	width: 100%;
	position: relative;
	line-height: 0;
	.section-inner {
		max-width: none;
		width: 100%;
		
		video {
			width: 100%;
			height: 100%;
		}
	}
}

.view-tagged-content {
	&.view-display-id-tagged_additional,
	&.view-display-id-tagged_sidebar,
	&.view-display-id-tagged_postscript {
		& > .view-content > .views-row {
			padding: 0;
			width: 100%;
		}
	}
}